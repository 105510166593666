const EXTENSION_URLS = {
  chrome: 'https://chrome.google.com/webstore/detail/mytonwallet/fldfpgipfncgndfolcbkdeeknbbbnhcc',
  firefox: 'https://addons.mozilla.org/ru/firefox/addon/mytonwallet/',
  edge: 'https://microsoftedge.microsoft.com/addons/detail/mytonwallet-%C2%B7-my-ton-wall/pgpdomeflfhcmgdbfdlociknopahmbej',
};

const MOBILE_URLS = {
  ios: 'https://apps.apple.com/ru/app/mytonwallet-anyway-ton-wallet/id6464677844',
  android: 'https://mytonwallet.app/get/android',
};

const DEFAULT_INSTALL_URL = 'https://mytonwallet.app/get';
const BROWSER_URL = 'https://mytonwallet.app';

document.addEventListener('DOMContentLoaded', () => {
  const btnGetForDesktop1 = document.querySelector('#get-for-desktop-1');
  const btnGetForChrome1 = document.querySelector('#get-for-chrome-1');
  const btnGetForDesktop2 = document.querySelector('#get-for-desktop-2');
  const btnGetForChrome2 = document.querySelector('#get-for-chrome-2');
  const secondaryGetButtons1 = document.querySelector('#secondary-get-buttons-1');
  const secondaryGetButtons2 = document.querySelector('#secondary-get-buttons-2');

  const platform = getPlatform();
  const isDesktop = ['macOS', 'Windows', 'Linux'].includes(platform);
  const isMobile = ['iOS', 'Android'].includes(platform);
  const browser = getBrowser();

  if (isMobile) {
    [btnGetForDesktop1, btnGetForDesktop2].forEach((btn) => {
      btn.href = MOBILE_URLS[platform.toLowerCase()] || DEFAULT_INSTALL_URL;
      btn.classList.add('no-icon');
      btn.querySelector('span').textContent = ['iOS', 'Android'].includes(platform)
        ? `Download for ${platform}`
        : 'Install';
    });

    [btnGetForChrome1, btnGetForChrome2].forEach((btn) => {
      btn.href = BROWSER_URL;
      btn.classList.add('no-icon');
      btn.classList.add('dimmed');
      btn.querySelector('span').textContent = 'Open in Browser';
    });

    [secondaryGetButtons1, secondaryGetButtons2].forEach((btn) => {
      btn.style.display = 'none';
    });
  } else if (isDesktop) {
    [btnGetForDesktop1, btnGetForDesktop2].forEach((btn) => {
      btn.querySelector('span').textContent = `Get for ${platform === 'macOS' ? 'Mac' : platform}`;
    });

    if (['Firefox', 'Edge'].includes(browser)) {
      [btnGetForChrome1, btnGetForChrome2].forEach((btn) => {
        btn.href = EXTENSION_URLS[browser.toLowerCase()];
        btn.classList.add('no-icon');
        btn.querySelector('span').textContent = `Get for ${browser}`;
      });
    }
  }
});

function getPlatform() {
  const {
    userAgent, platform,
  } = window.navigator;

  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  if (iosPlatforms.indexOf(platform) !== -1
    // For new IPads with M1 chip and IPadOS platform returns "MacIntel"
    || (platform === 'MacIntel' && ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 2))) {
    return 'iOS';
  }

  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  if (macosPlatforms.indexOf(platform) !== -1) return 'macOS';

  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  if (windowsPlatforms.indexOf(platform) !== -1) return 'Windows';

  if (/Android/.test(userAgent)) return 'Android';

  if (/Linux/.test(platform)) return 'Linux';

  return undefined;
}

function getBrowser() {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    return 'Chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    return 'Firefox';
  } else if (userAgent.match(/safari/i)) {
    return 'Safari';
  } else if (userAgent.match(/edg/i)) {
    return 'Edge';
  }

  return undefined;
}
